<template>
  <div class="enigma-container ">
    <overlay-navigation :hide-info="true" />
    <div class="canvas-container opacity-0">
      <canvas class="webgl-scene absolute top-0"></canvas>
    </div>
  </div>
</template>

<script>
import OverlayNavigation from '@/components/OverlayNavigation.vue'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import gsap from "gsap"
import { clearThree } from "../../helpers";
export default {
  name: 'Enigma2',
  components: {
    OverlayNavigation
  },
  data () {
    return {
      scene: null
    }
  },
  destroyed () {
    clearThree(this.scene)
  },
  mounted () {
    // Trackings
    this.$gtmTracking.pageView()
    this.$mmTro.sendRtg()
    let tlContainer = gsap.timeline()
    tlContainer.to('.canvas-container', {duration: 0.5, opacity: 1})
    // Canvas
    const canvas = document.querySelector('canvas.webgl-scene')
    // Scene
    this.scene = new THREE.Scene()

    // Cube
    const textureLoader = new THREE.TextureLoader();

    const textureCircleLogo = textureLoader.load( '/assets/3dspace/baseColor1.jpg' );
    const textureDecanter = textureLoader.load( '/assets/3dspace/baseColor2.jpg' );
    const textureTextWithClue = textureLoader.load( '/assets/3dspace/baseColor3.jpg' );
    const textureTextWithoutClue = textureLoader.load( '/assets/3dspace/baseColor4.jpg' );

    const materials = [
        new THREE.MeshBasicMaterial( { map: textureCircleLogo, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureCircleLogo, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureTextWithClue, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureTextWithoutClue, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureDecanter, side: THREE.BackSide} ),
        new THREE.MeshBasicMaterial( { map: textureDecanter, side: THREE.BackSide} )
    ];
    let faceMaterial = new THREE.MeshFaceMaterial( materials );
    
    let geometry = new THREE.BoxGeometry(3, 3, 3, 1, 1, 1)
    let cube = new THREE.Mesh(
        geometry,
        faceMaterial);
    
    cube.scale.x = -1
    this.scene.add(cube);

    // Size
    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }

    // Responsive
    window.addEventListener('resize', () =>
    {
      // Update sizes
      sizes.width = window.innerWidth
      sizes.height = window.innerHeight

      // Update camera
      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    })

    // Base camera
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
    camera.position.set(1, 0, 0)
    this.scene.add(camera)

    // Controls
    const controls = new OrbitControls(camera, canvas)
    // controls.target.set(0, 1, 0)
    controls.rotateSpeed *= -1
    controls.enableZoom = false
    controls.enableDamping = true

    /**
     * Renderer
     */
    const renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        alpha: true
    })
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    /**
     * Animate
     */
    const clock = new THREE.Clock()
    let previousTime = 0

    const tick = () =>
    {
        const elapsedTime = clock.getElapsedTime()
        const deltaTime = elapsedTime - previousTime
        previousTime = elapsedTime

        // console.log('momom', monolithe)

        // Update controls
        controls.update()

        // Render
        renderer.render(this.scene, camera)

        // Call tick again on the next frame
        window.requestAnimationFrame(tick)
    }

    tick()
  }
}
</script>

<style lang="scss" scoped>

</style>